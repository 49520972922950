import { Component, Renderer2 } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent {

  lang: string = "en";
  langChangeSubscription!: Subscription;

  constructor(public translate: TranslateService, private renderer: Renderer2){}

  ngOnInit(){
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const newLanguage = event.lang;
      this.lang = newLanguage;
    });
  }

  setLang(data: string) {
    this.lang = data;
    if (data === 'en') {
      this.switchLanguage('en');
      this.renderer.setAttribute(document.body, 'dir', 'ltr');
    }
    else if (data === 'ar') {
      this.switchLanguage('ar');
      this.renderer.setAttribute(document.body, 'dir', 'rtl');
    }
  }

  switchLanguage(lang: string) {
    this.translate.use(lang);
    // localStorage.setItem('language', lang);
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
