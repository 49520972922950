import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { BrowserModule, makeStateKey, TransferState } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderTopBoxComponent } from './header-top-box/header-top-box.component';
import { CurrentRouteService } from './services/current-route.service';
import { ScheduleDemoComponent } from './components/schedule-demo/schedule-demo.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { lastValueFrom } from 'rxjs';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const TRANSLATIONS_KEY = makeStateKey<any>('translations');


export function loadTranslations(
  translate: TranslateService,
  transferState: TransferState,
  platformId: object // Inject platformId to detect if it's running on the browser
) {
  return () => {
    const lang = "en"; //Default language
    // if (localStorage.getItem('language')  != undefined) {
    //   lang = localStorage.getItem('language') || 'en'; // Default language
    // }
    // return translate.use(lang).toPromise();
    if (isPlatformBrowser(platformId)) {
      const translations = transferState.get(TRANSLATIONS_KEY, null);
      if (translations) {
        translate.setTranslation(lang, translations, true);
        return Promise.resolve();
      } else {
        return lastValueFrom(translate.use(lang));
      }
    }  else if (isPlatformServer(platformId)) {
      return lastValueFrom(translate.getTranslation(lang)).then((translations) => {
        transferState.set(TRANSLATIONS_KEY, translations);
        translate.setTranslation(lang, translations, true);
      });
    } else {
      return Promise.resolve();
    }
  };
}






@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HeaderTopBoxComponent,
    LoadingComponent,


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSliderModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers: [CurrentRouteService, DatePipe, {
    provide: APP_INITIALIZER,
    useFactory: loadTranslations,
    deps: [TranslateService, PLATFORM_ID],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en'); // Set English as the default language
    translate.use('en'); // Load the default language
  }
}
