import {
  Component,
  HostListener,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

import { LoadingService } from './services/loading.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // isBrowser: boolean;
  isLoading = true;

  title = 'infithra-ssr';
  navHeight = 70;
  lastScrollY = 0;
  delta = 10;
  didScroll = false;

  loading = true;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    // @Inject(PLATFORM_ID) private platformId: object // Inject platformId for SSR detection
  ) {
    // let lang = 'en'
    // if (localStorage.getItem('language') != undefined  ) {
    //   lang = localStorage.getItem('language') || 'en';
    // }
    // translate.use(lang).subscribe(() => {
    //   this.loading = false;
    // });

    this.isRouteShow();
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(
      browserLang && browserLang.match(/en|ar/) ? browserLang : 'en'
    );

    // this.isBrowser = isPlatformBrowser(this.platformId);

    // If in the browser, hide the static spinner
    // if (this.isBrowser) {
    //   const loader = document.getElementById('global-loader');
    //   if (loader) {
    //     loader.style.display = 'none';
    //   }
    // }

    // Check route visibility logic
    this.isRouteShow();
  }

  routeArray: string[] = ['/login', '/authentication'];

  isRouteShow() {
    const route = this.router.url;
    // console.log(route)
    if (!this.routeArray.includes(route)) {
      return true;
    }
    return false;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled();
  }

  scrolled(): void {
    let sy = window.scrollY;
    if (Math.abs(this.lastScrollY - sy) > this.delta) {
      if (sy > this.lastScrollY && sy > this.navHeight) {
        this.didScroll = true;
      } else if (sy < this.lastScrollY) {
        this.didScroll = false;
      }
      this.lastScrollY = sy;
    }
  }

  ngOnInit(): void {
    const loader = document.getElementById('global-loader');
    if (loader) {
      loader.style.display = 'none';
    }
    this.isLoading = false; // Angular's own spinner logic

    this.loadingService.show();

    this.simulateFullPageLoad().then(() => {
      this.loadingService.hide();
      // this.isLoading = false; // Set loading to false
      this.cdRef.detectChanges();
      // this.checkAllResourcesLoaded();
    });
  }

  simulateFullPageLoad(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  }

  // checkAllResourcesLoaded() {

  //   this.isLoading = false;
  //   this.cdRef.detectChanges();
  // }
}
