import { Component, HostListener } from '@angular/core';
import { CurrentRouteService } from '../services/current-route.service';

@Component({
  selector: 'app-header-top-box',
  templateUrl: './header-top-box.component.html',
  styleUrls: ['./header-top-box.component.scss']
})
export class HeaderTopBoxComponent {
  userScrolledFromTop: boolean = false;
  routeClassNames: any = {
    '/core-hr-product-page': 'coreHrTopBgSec',
    '/integrations': 'inetgrationsTopBgSec',
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.userScrolledFromTop = window.scrollY > 20;
  }


  constructor(private currentRouteService: CurrentRouteService) { }
  topText: any = 'Optimize Your People and Payroll Operations with Cloud-Based HR.';
  topLink: any = 'Try infithra Today!';
  class: boolean = false;
  getRouteClass() {
    const route = this.currentRouteService.getRoute();

    switch (route) {
      case '/people-analytics':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
       

        break;
      case '/contact-us':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
       

        break;
      case '/leave-management':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
       

        break;
      case '/demo':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
       

        break;
      case '/product-demo':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
       

        break;
      case '/client-stories-page':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
       

        break;
      case '/integrations':
        this.topText = 'Happy Employees, Effortless HR: Experience the infithra Advantage.';
        this.topLink = 'Try it for Free!';
        
        break;
      default:
        return ''

    }

    return this.routeClassNames[route]
  }
}
