<!-- <div class="loading-overlay" *ngIf="isLoading">
  <div class="spinner"></div>
</div> -->

<div class="overlayWrapper" *ngIf="!isLoading">
  <div class="nav-layout" *ngIf="isRouteShow()" [class.navScroll]="didScroll">
    <app-header-top-box></app-header-top-box>
    <app-header></app-header>
  </div>
  <router-outlet></router-outlet>
  <app-footer *ngIf="isRouteShow()"></app-footer>
</div>
